// Custom style for loading elements without height
body {
  touch-action: manipulation;
}

.will-load {
  min-height: 80px;
}

.flag-icon {
  width: 24px;
  margin-top: 14px;
  margin-left: 10px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
}

.mat-card-content {
  margin-bottom: 0 !important;
}

mat-card-title button[mat-icon-button] {
  margin-top: -7px;
}

.full-width {
  width: 100% !important;
}

.mat-list-item-content {
  width: 100%;
}

.bar-footer {
  text-align: center;
  font-size: 0.8em;
  color: #a6a6a6;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.mat-error {
  float: right;
}

#slidecontainer > .thumb {
  display: none !important;
}

mat-hint.hint-error {
  width: 100%;
  text-align: right;
  color: #fa323c !important;
}

.primary-green {
  color: white;
  background: #059e74 !important;
  background-color: #059e74 !important;

  .mat-simple-snackbar-action {
    color: black;
  }
}

.right_close_btn {
  float: right !important;
  position: absolute !important;
  margin-top: -10px !important;
}

.fixed-action-btn.horizontal.click-to-toggle > a {
  border: 3px solid white;

  i {
    margin: -3px;
  }
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

#footer_padding {
  height: 170px;
}

// Href line height wasn't right for mat-icon-button
a[md-icon-button] {
  line-height: 36px;
}

.divider-margin {
  margin: 10px 0 10px 0 !important;
}

// Capitalize
.text-capital {
  text-transform: capitalize;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.terms {
  color: #059e74 !important;
}

.grey-color {
  color: #9e9e9e !important;
}

.note-yellow {
  color: #f4b400 !important;
}

// Lighter font weight in sub-toolbarss
td-layout-manage-list .mat-toolbar {
  font-weight: 400;
}

.mat-checkbox-inner-container {
  margin-right: 8px !important;
}

/** td-dialogs */
.td-dialog-title {
  margin-top: 0;
  margin-bottom: 20px;
}

.td-dialog-content {
  margin-bottom: 16px;
}

.td-dialog-actions {
  position: relative;
  top: 16px;
  left: 16px;
}

.date-btn {
  color: #000000 !important;
  background-color: #e0e0e0 !important;
  border: 1px solid #a5a5a5 !important;
  margin: 0 10px !important;
}

.mat-list-base {
  padding-top: 0 !important;
}

.app-expansion-panel {
  .mat-expansion-panel-header {
    font-size: 24px !important;
    font-weight: 500 !important;
  }

  .mat-expansion-panel-header {
    background: whitesmoke !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }

  .mat-expansion-panel-header:hover {
    background: whitesmoke !important;
    opacity: 0.8;
  }

  .mat-expansion-panel-body {
    padding: 0 !important;
  }
}

.app-expansion-panel.mat-expanded {
  .mat-expansion-indicator {
    margin-top: 10px;
  }
}

:host {
  display: block;

  .td-dialog-actions {
    button {
      text-transform: uppercase;
      margin-left: 8px;

      [dir='rtl'] & {
        margin-right: 8px;
        margin-left: inherit;
      }

      padding-left: 8px;
      padding-right: 8px;
      min-width: 64px;
    }
  }
}

.form-wrapper {
  width: 450px;
}

.form-wrapper.big {
  width: 1050px;
}

@media only screen and (max-width: 250px) {
  td-dialog {
    max-width: 200px;
  }
}

@media only screen and (min-width: 250px) and (max-width: 300px) {
  td-dialog {
    min-width: 200px;
    max-width: 250px;
  }
}

@media only screen and (min-width: 300px) and (max-width: 350px) {
  td-dialog {
    min-width: 250px;
    max-width: 300px;
  }
}

@media only screen and (min-width: 350px) and (max-width: 400px) {
  td-dialog {
    min-width: 300px;
    max-width: 350px;
  }
}

@media only screen and (min-width: 400px) and (max-width: 500px) {
  td-dialog {
    min-width: 350px;
    max-width: 400px;
  }
}

@media only screen and (min-width: 500px) and (max-width: 600px) {
  td-dialog {
    min-width: 400px;
    max-width: 500px;
  }
}

@media only screen and (min-width: 600px) {
  td-dialog {
    min-width: 400px;
    max-width: 500px;
  }
}

@media (max-width: 599px) {
  .form-wrapper {
    width: 100% !important;
  }
  .cdk-overlay-pane {
    max-width: 95vw !important;
  }
}

@media (max-width: 1050px) {
  .form-wrapper.big {
    width: 100% !important;
  }
}
